const module = {}

module.exports = {
    colors: {
        transparent: 'transparent',
        current: 'currentColor',
        brand1: {
            DEFAULT: '#004B9A',
            light: '#3C9BFF',
            lightest: '#56ABFF'
        },
        brand2: '#000',
        black: '#000',
        white: '#fff',
        gray: {
            darkest: '#585858',
            dark: '#707070',
            DEFAULT: '#9f9f9f',
            light: '#dedede',
            lightest: '#f3f2f2',
        },
        blue: {
            darkest: '#002f5f',
            dark: '#3b73ab',
            DEFAULT: '#007ec5',
            light: '#007ec5',
            lightest: '#dedede',
        },
        pink: {
            dark: '#57004f',
            DEFAULT: '#83427D',
            light: '#C1A1BE'
        },
        danger: {
            DEFAULT: '#f00',
                light: '#f88',
                lightest: '#faa',
        }
    },
    fontFamily: {
        sans: ['Helvetica', 'sans-serif'],
        serif: ['Merriweather', 'serif'],
    }
}

export default module.exports