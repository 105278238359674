
export default class DataConsumer {
    constructor(dataSource) {
        this.dataSource = dataSource
        this.changed = (...args) => this.onDataSourceChanged(...args)
        if (dataSource) dataSource.onInvalidate.register(this.changed)
    }

    getDataSource() {
        return this.dataSource
    }

    setDataSource(dataSource) {
        if(this.dataSource !== dataSource) {
            if (this.dataSource) {
                this.dataSource.onInvalidate.unregister(this.changed)
            }
            this.dataSource = dataSource
            if (this.dataSource) {
                this.dataSource.onInvalidate.register(this.changed)
            }
            this.onDataSourceChanged(this.dataSource, 'source', 0)
        }
    }

    onDataSourceChanged(dataSource, operation, id) {

    }
}
