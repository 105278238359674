
import DataConsumer from './DataConsumer.js'
import {DataException} from './DataSource.js'
import {ObjectEvent} from '../ObjectEvent.js'

export default class ManagedEntity extends DataConsumer {
    constructor(dataSource, fetchImmediately, entity) {
        super(dataSource)
        this.dataSource = dataSource
        this.onStateChange = new ObjectEvent(this)
        this.onAvailabilityChange = new ObjectEvent(this)
        this.fetchImmediately = Boolean(fetchImmediately)
        if (entity) {
            this.setState('loaded')
            this.entity = entity
        } else {
            this.entity = {
                id: 0
            }
            this.invalidate()
        }
    }

    onDataSourceChanged(dataSource, operation, id) {
        super.onDataSourceChanged(dataSource, operation, id);
        if (id === this.entity.id) this.invalidate()
    }

    reset() {
        this.entity = { id: 0 }
        this.state = 'pending'
    }


    getState() {
        return this.state
    }
    
    setState(state) {
        const originalState = this.state
        if (state !== originalState) {
            this.state = state
            this.onStateChange.dispatch(state, originalState)
        }
    }

    isAvailable() {
        return this.entity && this.entity.id !== 0
    }

    checkAvailable() {
        const available = this.isAvailable()
        if (this.available !== available) {
            this.available = available
            this.onAvailabilityChange.dispatch(available)
        }
    }

    getLastMessage() {
        return this.lastMessage
    }

    getLastCode() {
        return this.lastCode || 0
    }

    getLastAction() {
        return this.lastCode || 0
    }

    updateComputed() {
        this.checkAvailable()
    }

    handleException(ex) {
        this.lastAction = this.state
        if (ex instanceof DataException) {
            this.lastCode = ex.code
            this.lastMessage = ex.message
        } else {
            throw ex
        }
    }

    async load(id) {
        try {
            this.setState('loading')
            this.entity = await this.dataSource.load(id || this.entity.id) || this.entity
        } catch (ex) {
            this.handleException(ex)
        } finally {
            this.setState('loaded')
            this.updateComputed()
        }
    }

    async create(entity) {
        try {
            this.setState('creating')
            this.entity = await this.dataSource.create(entity) || this.entity
        } catch (ex) {
            this.handleException(ex)
        } finally {
            this.setState('loaded')
            this.updateComputed()
        }
    }

    async update() {
        try {
            this.setState('updating')
            this.entity = await this.dataSource.update(this.entity) || this.entity
        } catch (ex) {
            this.handleException(ex)
        } finally {
            this.setState('loaded')
            this.updateComputed()
        }
    }

    async delete() {
        try {
            this.setState('deleting')
            await this.dataSource.delete(this.entity.id)
            this.entity = { id: 0 }
        } catch (ex) {
            this.handleException(ex)
        } finally {
            this.setState('loaded')
            this.updateComputed()
        }
    }

    async ensure() {
        if (this.state === 'pending') {
            await this.load()
        }
    }

    invalidate() {
        if (this.state !== 'pending') {
            this.setState('pending')
            if (this.fetchImmediately) {
                this.ensure()
            }
        }
    }
}
