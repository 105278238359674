import Table from './Table.js'
import m from '../../vendor/mithril.js'
import DataSource from '../../n2/data/DataSource.js'

const baseUrl = '/'
const dataSource = (collectionName, fetchAll) => new DataSource(baseUrl + 'data/' + collectionName, fetchAll)

const tables = dataSource('tables')
function handleVisibilityChange() {
  if (!document.hidden) {
    CheckIn.table.invalidate()
  }
}

document.addEventListener("visibilitychange", handleVisibilityChange, false);

const CheckIn = {
    firstname: '',
    lastname: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    email: '',
    privacy: '',

    persons: [],

    tables: tables,
    table: new Table(tables),

    state: 'input',

    getTable: () => CheckIn.table,

    canSend: () => {
        let first = CheckIn.firstname.trim().length > 1 && CheckIn.lastname.trim().length > 1 && CheckIn.street.trim().length > 1 && CheckIn.zip.trim().length > 1 && CheckIn.city.trim().length > 1 && CheckIn.phone.trim().length > 1 && CheckIn.privacy
        let persons = true
        CheckIn.persons.map((person) => {
            if (person.firstname.trim().length > 1 && person.lastname.trim().length > 1 && person.street.trim().length > 1 && person.zip.trim().length > 1 && person.city.trim().length > 1 && person.phone.trim().length > 1) {
            } else {
                persons = false
            }
        })
        return first && persons
    },

    reset: () => {
        CheckIn.firstname = ''
        CheckIn.lastname = ''
        CheckIn.street = ''
        CheckIn.zip = ''
        CheckIn.city = ''
        CheckIn.phone = ''
        CheckIn.email = ''
        CheckIn.privacy = ''
        CheckIn.persons = []
    },

    addPerson: () => {
        CheckIn.persons.push({
            firstname: '',
            lastname: '',
            street: '',
            zip: '',
            city: '',
            phone: '',
            email: '',
        })
    },

    removePerson: (i) => {
        CheckIn.persons.splice(i, 1)
    },

    copyPerson: (i) => {
        CheckIn.persons[i].lastname = CheckIn.lastname
        CheckIn.persons[i].street = CheckIn.street
        CheckIn.persons[i].zip = CheckIn.zip
        CheckIn.persons[i].city = CheckIn.city
        CheckIn.persons[i].phone = CheckIn.phone
        CheckIn.persons[i].email = CheckIn.email
    },

    send: () => new Promise((resolve, reject) => {
        if (CheckIn.canSend()) {
            CheckIn.state = 'waiting'
            m.request({
                method: 'POST',
                url: 'checkin',
                body: {
                    firstname: CheckIn.firstname,
                    lastname: CheckIn.lastname,
                    street: CheckIn.street,
                    zip: CheckIn.zip,
                    city: CheckIn.city,
                    phone: CheckIn.phone,
                    email: CheckIn.email,
                    persons: CheckIn.persons,
                    code: code,
                }
            })
            .then((result) => {
                CheckIn.state = result.status === 'ok' ? 'success' : 'input'
                CheckIn.message = result.message
                CheckIn.reset()
                CheckIn.table.invalidate()
                resolve()
            })
            .catch(reject)
        } else {
            reject()
        }
    }),

    sendOff: () => new Promise((resolve, reject) => {
        CheckIn.state = 'waiting'
        m.request({
            method: 'POST',
            url: 'checkout',
            body: {
                code: code,
            }
        })
        .then((result) => {
            CheckIn.state = result.status === 'ok' ? 'success' : 'input'
            CheckIn.message = result.message
            resolve()
        })
        .catch(reject)
    })
}

export default CheckIn
