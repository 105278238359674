
export class ObjectEvent {
    constructor(source) {
        this.source = source
        this.consumers = []
        this.dispatching = false
    }

    register(obj, fun) {
        this.consumers.push(fun ? [new WeakRef(obj), fun] : [obj, undefined])
    }

    unregister(obj, fun) {
        this.consumers = this.consumers.filter(
            c => (c[0] instanceof WeakRef ? c[0].deref() : c[0]) !== obj && c[1] !== fun
        )
    }

    dispatch(...args) {
        if (!this.dispatching) { // protection against event cycling
            try {
                this.dispatching = true
                const newConsumers = []
                for (let consumerRef of this.consumers) {
                    let [obj, fun] = consumerRef
                    obj = obj instanceof WeakRef ? obj.deref() : obj
                    if (obj) {
                        newConsumers.push(consumerRef)
                        if (typeof obj === 'function') {
                            obj(this.source, ...args)
                        } else if (fun && typeof obj[fun] === 'function') {
                            obj[fun](this.source, ...args)
                        }
                    }
                }
                this.consumers = newConsumers
            } finally {
                this.dispatching = false
            }
        }
    }
}