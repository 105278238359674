import {m, Button, Input, Control} from '../../n2/components/base.js'
import { Modal } from '../../n2/components/modal.js'
import {Frame, Layout} from './layout.js'

import CheckIn from '../state/CheckIn.js'
import {toDateString, toTimeString} from '../../n2/dateTime.js'

const onCheckIn = () => CheckIn.send()//.then(() => window.location.reload())
const onCheckOut = () => CheckIn.sendOff().then(() => window.location.reload())
const addPerson = () => CheckIn.addPerson()
const removePerson = (i) => CheckIn.removePerson(i)
const copyPerson = (i) => CheckIn.copyPerson(i)
const onKeyUp = (ev) => ev.code === 'Enter' && onCheckIn()
const sizeClass = 'w-72 py-2 px-3 my-1 mx-1'
const sizeClassHalf = 'py-2 px-3 my-1'

async function openPrivacyModal(level) {
    const modal = new Modal({
        title: 'Datenschutzerklärung',
        renderContent: () => m('div', [
            m('p.pb-2', m('strong', '1. Datenschutz auf einen Blick')),
            m('p', 'Allgemeine Hinweise'),
            m('p.pb-2', 'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie dieses Portal besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.'),

            m('p', 'Datenerfassung auf diesem Portal'),
            m('p.pb-2', 'Wer ist verantwortlich für die Datenerfassung?'),
            m('p.pb-2', 'Die Datenverarbeitung erfolgt durch den Betreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.'),

            m('p', 'Wie erfassen wir Ihre Daten?'),
            m('p.pb-2', 'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.'),

            m('p.pb-2', 'Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch des Portals durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Aufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie dieses Portal betreten.'),

            m('p', 'Wofür nutzen wir Ihre Daten?'),
            m('p.pb-2', 'Zahlreiche Branchen und Einrichtungen müssen die Kontaktdaten aller Kundinnen und Kunden/Besucherinnen und Besucher/Teilnehmenden erfassen, wenn diese die Geschäftsräume oder Einrichtungen betreten bzw. an einer Veranstaltung teilnehmen wollen. Dies gilt gemäß § 5 der Niedersächsischen Verordnung über Maßnahmen zur Eindämmung des Corona-Virus SARS-CoV-2.'),

            m('p', 'Sofern eine Dokumentationspflicht besteht, bezieht sich diese für alle Branchen und Einrichtungen auf folgende Daten:'),
            m('p.pb-2', [
                'Familienname,',
                m('br'),
                'Vorname,',
                m('br'),
                'vollständige Anschrift,',
                m('br'),
                'eine Telefonnummer oder E-Mail-Adresse,',
                m('br'),
                'Erhebungsdatum und -uhrzeit',
            ]),

            m('p', 'Welche Rechte haben Sie bezüglich Ihrer Daten?'),
            m('p.pb-2', 'Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.'),

            m('p.pb-2', 'Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.'),

            m('p.pb-2', m('strong', '2. Allgemeine Hinweise und Pflicht­informationen')),
            m('p', 'Datenschutz'),
            m('p.pb-2', 'Die Betreiber nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.'),

            m('p.pb-2', 'Wenn Sie dieses Portal benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.'),

            m('p.pb-2', 'Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.'),

            m('p', 'Hinweis zur verantwortlichen Stelle'),
            m('p.pb-2', 'Die verantwortliche Stelle für die Datenverarbeitung auf diesem Portal ist:'),

            m('p.pb-2', [
                'NEXTcontrol GmbH',
                m('br'),
                'Bahnhofstraße 10',
                m('br'),
                '27472 Cuxhaven',
                m('br'),
                m('br'),
                'Telefon: 047216643950',
                m('br'),
                'E-Mail: datenschutz@nextcontrol.de',
                m('br'),
                m('br'),
                'Telefon: 047216643950',
                m('br'),
                'E-Mail: datenschutz@nextcontrol.de',
            ]),

            m('p.pb-2', 'Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.'),

            m('p', 'Speicherdauer'),
            m('p.pb-2', 'Ziel der Dokumentation ist es lt. § 5 Abs. 1 Satz 3 CoronaVO etwaige Infektionsketten nachzuvollziehen. Daher sind die zu erhebenden Daten für die Dauer von vier Wochen nach dem Ende des jeweiligen Ereignisses aufzubewahren.'),

            m('p.pb-2', 'Spätestens einen Monat nach dem Ende des jeweiligen Ereignisses müssen die Kontaktdaten gelöscht werden.'),

            m('p.pb-2', 'Gesetzlich vorgeschriebener Datenschutz­beauftragter'),
            m('p.pb-2', 'Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.'),

            m('p.pb-2', [
                'Avallon GmbH',
                m('br'),
                'Blücherstraße 2a',
                m('br'),
                '26603 Aurich',
                m('br'),
                m('br'),
                'Telefon: 049419839070',
                m('br'),
                'E-Mail: dsb@avallon.de',
                m('br'),
            ]),

            m('p', 'Widerruf Ihrer Einwilligung zur Datenverarbeitung'),
            m('p.pb-2', 'Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.'),

            m('p.pb-2', 'Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)'),
            m('p.pb-2', 'WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).'),

            m('p.pb-2', 'WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).'),

            m('p', 'Beschwerde­recht bei der zuständigen Aufsichts­behörde'),
            m('p.pb-2', 'Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.'),

            m('p', 'Recht auf Daten­übertrag­barkeit'),
            m('p.pb-2', 'Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.'),

            m('p', 'SSL- bzw. TLS-Verschlüsselung'),
            m('p.pb-2', 'Dieses Portal nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Betreiber senden, eine SSL- bzw. TLS-Verschlüsselung.'),

            m('p.pb-2', 'Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.'),

            m('p', 'Auskunft, Löschung und Berichtigung'),
            m('p.pb-2', 'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.'),

            m('p', 'Recht auf Einschränkung der Verarbeitung'),
            m('p.pb-2', 'Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:'),

            m('p.pb-2', 'Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.'),
            m('p.pb-2', 'Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.'),
            m('p.pb-2', 'Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.'),
            m('p.pb-2', 'Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.'),
            m('p.pb-2', 'Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.'),

            m('p', m('strong', '3. Datenerfassung auf diesem Portal')),
            m('p', 'Cookies'),
            m('p.pb-2', 'Unser Portal verwendet so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung erfolgt.'),

            m('p.pb-2', 'Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unser Portal betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).'),

            m('p.pb-2', 'Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Funktionen ohne diese nicht funktionieren würden. Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.'),

            m('p.pb-2', 'Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung des Portals (z. B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Betreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.'),



            m('p.pb-2', 'Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.'),

            m('p', 'Server-Log-Dateien'),
            m('p.pb-2', 'Der Provider erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Endgerät automatisch an uns übermittelt. Dies sind:'),

            m('ul.pb-2', [
                m('li', 'Browsertyp und Browserversion'),
                m('li', 'verwendetes Betriebssystem'),
                m('li', 'Referrer URL'),
                m('li', 'Hostname des zugreifenden Endgeräts'),
                m('li', 'Uhrzeit der Serveranfrage'),
                m('li', 'IP-Adresse'),
            ]),
            m('p.pb-2', 'Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.'),

            m('p.pb-2', 'Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Betreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seines Portals – hierzu müssen die Server-Log-Files erfasst werden.'),

            m('p', 'Registrierung auf diesem Portal'),
            m('p.pb-2', 'Sie können sich auf diesem Portal registrieren, um zusätzliche Funktionen zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.'),

            m('p.pb-2', 'Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.'),

            m('p.pb-2', 'Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchführung des durch die Registrierung begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6 Abs. 1 lit. b DSGVO).'),

            m('p.pb-2', 'Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf diesem Portal registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.'),
        ]),
        cancel: {
            label: 'Ok'
        },
        /*accept: {
            label: 'Ja',
            action: () => {
                modal.hide()
                return false
            }
        },*/
        level: level
    })
    return await modal.show()
}

export default class CheckInPage extends Control {
    constructor(vnode) {
        super(vnode, 'div')
    }

    renderChildren() {
        if (CheckIn.getTable().getIsFree() || CheckIn.getTable().getNumber() == '') {
            return [
                this.renderCheckInPage(),
                Modal.view()
            ]
        } else {
            return [
                this.renderCheckOutPage(),
                Modal.view()
            ]
        }
    }

    renderCheckInPage() {
        return m(Frame, {
            background: '#FFFFFF',
        }, [
            m('.flex.flex-col.place-self-center', [
                m('.self-center.flex.flex-col', [
                    m('img', {
                        class: 'mb-4 mt-4',
                        src: '/assets/branding.png',
                        style: 'width: 300px;'
                    })
                ]),
                m('.self-center.flex.flex-col.p-9', [
                    m('a.rounded-sm.font-bold.focus:outline-none.w-72.py-2.px-3.my-1.mx-1.bg-danger.border.border-danger.disabled:bg-danger-light.text-white.py-1.px-2.text-center', {
                        href: '/Eiskarte.pdf',
                        target: '_blank'
                    }, 'Eiskarte')
                ]),
                m('.self-center.flex.flex-col.p-9', [
                    CheckIn.message && m('h2.text-center.text-white.font-bold.mb-4.bg-blue.p-2', CheckIn.message),
                    CheckIn.getTable().getNumber() && m('.mb-4', [
                        m('strong', 'Tischnummer: '),
                        m('span', CheckIn.getTable().getNumber()),
                    ]),
                    m(Input, {
                        class: sizeClass,
                        type: 'text',
                        placeholder: 'Vorname',
                        required: true,
                        value: CheckIn.firstname,
                        onChange: (value) => CheckIn.firstname = value,
                        onkeyup: onKeyUp,
                        oncreate: ({dom}) => dom.focus(),
                    }),
                    m(Input, {
                        class: sizeClass,
                        type: 'text',
                        placeholder: 'Nachname',
                        required: true,
                        value: CheckIn.lastname,
                        onChange: (value) => CheckIn.lastname = value,
                        onkeyup: onKeyUp,
                    }),
                    m(Input, {
                        class: sizeClass,
                        type: 'text',
                        placeholder: 'Straße',
                        required: true,
                        value: CheckIn.street,
                        onChange: (value) => CheckIn.street = value,
                        onkeyup: onKeyUp,
                    }),
                    m(Input, {
                        class: sizeClass,
                        type: 'text',
                        placeholder: 'PLZ',
                        required: true,
                        value: CheckIn.zip,
                        onChange: (value) => CheckIn.zip = value,
                        onkeyup: onKeyUp,
                    }),
                    m(Input, {
                        class: sizeClass,
                        type: 'text',
                        placeholder: 'Ort',
                        required: true,
                        value: CheckIn.city,
                        onChange: (value) => CheckIn.city = value,
                        onkeyup: onKeyUp,
                    }),
                    m(Input, {
                        class: sizeClass,
                        type: 'text',
                        placeholder: 'Telefon',
                        required: true,
                        value: CheckIn.phone,
                        onChange: (value) => CheckIn.phone = value,
                        onkeyup: onKeyUp,
                    }),
                    m(Input, {
                        class: sizeClass,
                        type: 'email',
                        placeholder: 'E-Mail',
                        value: CheckIn.email,
                        onChange: (value) => CheckIn.email = value,
                        onkeyup: onKeyUp,
                    }),
                    CheckIn.persons.map((person, i) => {
                        return [
                            m('hr.my-3'),
                            m(Input, {
                                class: sizeClass,
                                type: 'text',
                                placeholder: 'Vorname',
                                required: true,
                                value: person.firstname,
                                onChange: (value) => person.firstname = value,
                                onkeyup: onKeyUp,
                                oncreate: ({dom}) => dom.focus(),
                            }),
                            m(Input, {
                                class: sizeClass,
                                type: 'text',
                                placeholder: 'Nachname',
                                required: true,
                                value: person.lastname,
                                onChange: (value) => person.lastname = value,
                                onkeyup: onKeyUp,
                            }),
                            m(Input, {
                                class: sizeClass,
                                type: 'text',
                                placeholder: 'Straße',
                                required: true,
                                value: person.street,
                                onChange: (value) => person.street = value,
                                onkeyup: onKeyUp,
                            }),
                            m(Input, {
                                class: sizeClass,
                                type: 'text',
                                placeholder: 'PLZ',
                                required: true,
                                value: person.zip,
                                onChange: (value) => person.zip = value,
                                onkeyup: onKeyUp,
                            }),
                            m(Input, {
                                class: sizeClass,
                                type: 'text',
                                placeholder: 'Ort',
                                required: true,
                                value: person.city,
                                onChange: (value) => person.city = value,
                                onkeyup: onKeyUp,
                            }),
                            m(Input, {
                                class: sizeClass,
                                type: 'text',
                                placeholder: 'Telefon',
                                required: true,
                                value: person.phone,
                                onChange: (value) => person.phone = value,
                                onkeyup: onKeyUp,
                            }),
                            m(Input, {
                                class: sizeClass,
                                type: 'email',
                                placeholder: 'E-Mail',
                                value: person.email,
                                onChange: (value) => person.email = value,
                                onkeyup: onKeyUp,
                            }),
                            m(Layout.HBox, {
                                class: 'mx-1 w-72'
                            }, [
                                m(Button, {
                                    class: sizeClassHalf + ' flex-grow mr-1',
                                    kind: 'secondary',
                                    size: 'tiny',
                                    style: 'font-size: 0.75rem;',
                                    onclick: () => copyPerson(i)
                                }, [
                                    'Daten übernehmen' || CheckIn.message
                                ]),
                                m(Button, {
                                    class: sizeClassHalf + ' flex-grow ml-1',
                                    kind: 'danger',
                                    size: 'tiny',
                                    style: 'font-size: 0.75rem;',
                                    onclick: () => removePerson(i)
                                }, [
                                    'Person entfernen' || CheckIn.message
                                ]),
                            ]),
                        ]
                    }),
                    m(Button, {
                        class: sizeClass,
                        classes: '.mt-4',
                        kind: 'secondary',
                        onclick: addPerson
                    }, [
                        'Weitere Person eintragen' || CheckIn.message
                    ]),
                    m('.space-x-1.my-1.w-72', [
                        m('input.rounded-sm border border-gray-light pl-1 focus:outline-none focus:border-brand1-light', {
                            id: 'privacy',
                            type: 'checkbox',
                            value: 1,
                            checked: CheckIn.privacy,
                            oninput: (ev) => CheckIn.privacy = Boolean(ev.target.checked),
                            onkeyup: onKeyUp,
                        }),
                        m('label', { for: 'privacy' }, [
                            'Die ',
                            m('a', {
                                href: '#',
                                class: 'text-brand1 underline',
                                onclick: (ev) => {
                                    ev.preventDefault()
                                    openPrivacyModal(0)
                                    console.log('dooo')
                                }
                            }, 'Datenschutzerklärung'),
                            ' habe ich gelesen und akzeptiert.'
                        ]),
                    ]),
                    m(Button, {
                        class: sizeClass,
                        classes: '.mt-4',
                        disabled: !CheckIn.canSend(),
                        onclick: onCheckIn
                    }, [
                        'CheckIn' || CheckIn.message
                    ]),
                ])
            ])
        ])
    }

    renderCheckOutPage() {
        return m(Frame, {
            background: '#FFFFFF',
        }, [
            m('.flex.flex-col.place-self-center', [
                m('.self-center.flex.flex-col', [
                    m('img', {
                        class: 'mb-4',
                        src: '/assets/branding.png',
                        style: 'width: 300px;'
                    })
                ]),
                m('.self-center.flex.flex-col.p-9', [
                    m('a.rounded-sm.font-bold.focus:outline-none.w-72.py-2.px-3.my-1.mx-1.bg-danger.border.border-danger.disabled:bg-danger-light.text-white.py-1.px-2.text-center', {
                        href: '/Eiskarte.pdf',
                        target: '_blank'
                    }, 'Eiskarte')
                ]),
                m('.self-center.flex.flex-col.py-5', [
                    m('.px-3', [
                        m('.mb-4', [
                            m('strong', 'Tischnummer: '),
                            m('span', CheckIn.getTable().getNumber()),
                        ]),
                        m('.mb-4', [
                            m('strong', 'Name: '),
                            m('span', CheckIn.getTable().getVisitorName()),
                        ]),
                        m('.mb-4', [
                            m('strong', 'CheckIn Zeit: '),
                            m('span', toDateString(CheckIn.getTable().getVisitorCheckIn()) + ' ' + toTimeString(CheckIn.getTable().getVisitorCheckIn())),
                        ]),
                        m(Button, {
                            class: sizeClass,
                            onclick: onCheckOut
                        }, [
                            'CheckOut' || CheckIn.message
                        ]),
                    ])
                ])
            ])
        ]);
    }
}
