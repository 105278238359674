import m from '../../vendor/mithril.js';
import {Control} from './base.js'

const divide = (components, edgeClasses, middleClasses) => {
    if (components.length === 1) {
        return m(middleClasses, components[0])
    } else if (components.length > 1) {
        return [
            m(edgeClasses, components[0]),
            m(middleClasses, components.slice(1, components.length - 1)),
            m(edgeClasses, components[components.length - 1])
        ]
    } else {
        return null
    }
}

const separatedChildren = (vnode) => {
    const separator = vnode && vnode.attrs && vnode.attrs.separator
    let children = vnode.children
    if (children && children.length > 1 && separator) {
        children = []
        for (const [i, child] of vnode.children.entries()) {
            children.push(child)
            if (i < vnode.children.length - 1) children.push(separator())
        }
    }
    return children
}

const randomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

const getConfigClasses = (vnode) =>
    (vnode.attrs.justify ? '.justify-' + vnode.attrs.justify : '') +
    (vnode.attrs.align ? '.items-' + vnode.attrs.align : '') +
    (vnode.attrs.grow ? '.flex-grow' : '')

export const Layout = {
    TestDiv: {
        view: (vnode) => m('div', { style: { backgroundColor: randomColor() } }, 'Test')
    },
    HBox: {
        view: (vnode) => m('.flex.flex-row' + getConfigClasses(vnode), {class: vnode.attrs.class}, separatedChildren(vnode))
    },
    VBox: {
        view: (vnode) => m('.flex.flex-col' + getConfigClasses(vnode), {class: vnode.attrs.class}, separatedChildren(vnode))
    },
    PaddedHBox: {
        view: (vnode) => m('.flex.flex-row.space-x-2' + getConfigClasses(vnode), {class: vnode.attrs.class}, separatedChildren(vnode))
    },
    PaddedVBox: {
        view: (vnode) => m('.flex.flex-col.space-y-2' + getConfigClasses(vnode), {class: vnode.attrs.class}, separatedChildren(vnode))
    },
    HTriple: {
        view: (vnode) => m(
            '.flex.flex-row.flex-grow.h-full',
            divide(vnode.children, '.flex.flex-row.flex-grow-0', '.flex-grow.justify-center')
        )
    },
    VTriple: {
        view: (vnode) => m(
            '.flex.flex-col.flex-grow.v-full',
            divide(vnode.children, '.flex.flex-col.flex-grow-0', '.flex-grow.justify-center')
        )
    },
}

export const TopBar = {
    view: (vnode) =>
        m('.w-screen.h-150.bg-white', [
            m('.flex.flex-row.divide-x.divide-solid.divide-brand1', [
                m('img.mt-4.ml-5.mr-3.mb-2.h-8', {src: '/assets/logo-left.svg'}),
                m('.flex-grow.flex.flex-row.content-end.items-end.pr-3', [
                    m('.flex.flex-col.flex-grow.justify-end.pl-5', [
                        m('div.align-text-bottom.text-2xl.leading-tight', [
                            m('span.text-title.font-bold.mr-1', ((vnode.attrs.title || '')).toUpperCase()),
                            m('span.text-title.text-brand1', (vnode.attrs.subTitle || '').toUpperCase())
                        ])
                    ]),
                    ...vnode.children.slice(0, 1)
                ]),
                m('.flex.flex-row.items-end.pl-4.pr-6', vnode.children.slice(1))
            ]),
            m('div.h-2.bg-red')
        ])
}

export const BottomBar = {
    view: (vnode) => m(
        '.flex.w-screen.h-3.bg-brand1',
    )
}

export class Frame extends Control {
    constructor(vnode) {
        super(vnode, '.flex.flex-col.items-center.h-screen.bg-gray-light')
    }

    getBackgroundSrc() {
        return this.attrs.backgroundSrc
    }

    getTitle() {
        return this.attrs.title
    }

    getSubTitle() {
        return this.attrs.subTitle
    }

    renderBodySections() {
        return this.vnode.children
    }

    renderBody() {
        return m('.w-full.h-full.relative.overflow-hidden', [
            m('.absolute.left-0.top-0.w-full.h-full.flex.flex-grow.justify-center.overflow-auto',
                this.renderBodySections()
            ),
            m('.pointer-events-none.absolute.left-0.w-full.shadow-lg', { style: { height: '50px', top: '-50px' } }),
        ])
    }

    renderTopBarComponents() {
        return []
    }

    renderChildren() {
        return [
            m(TopBar, {
                title: this.getTitle(),
                subTitle: this.getSubTitle()
            }, this.renderTopBarComponents()),
            this.renderBody(),
            m(BottomBar)
        ]
    }

    getAttributes() {
        const backgroundSrc = this.getBackgroundSrc()
        return Object.assign(super.getAttributes(), {
            style: backgroundSrc && {
                backgroundImage: 'url("' + backgroundSrc + '")',
                backgroundPosition: 'center bottom',
                backgroundSize: 'cover'
            }
        })
    }
}

