
export default class EntityCollection {

    constructor() {
        this.setEntities()
    }

    setEntities(entities) {
        entities = Array.isArray(entities) ? entities : []
        this.groups = {}
        this.byId = {}
        this.array = []
        for (let entity of entities) {
            this.array.push(entity)
            this.byId[entity.id] = entity
        }
    }

    with(property, value, valueExtractor) {
        let extractor = valueExtractor || ((e) => [e[property]])
        let group = this.groups[property]
        if (!group) {
            group = this.groups[property] = {}
            for (let entity of this.array) {
                for (let propertyValue of extractor(entity)) {
                    let valueEntries = group[propertyValue]
                    if (!valueEntries) {
                        valueEntries = group[propertyValue] = []
                    }
                    valueEntries.push(entity)
                }
            }
        }
        return group[value] || []
    }
}
