import m from '../../vendor/mithril.js'
import ManagedEntity from '../../n2/data/ManagedEntity.js'

export default class Table extends ManagedEntity {
    constructor(userDataSource) {
        super(userDataSource, true)
    }

    getVisitorName() {
        return this.entity.lastVisitorName;
    }

    getVisitorCheckIn() {
        return this.entity.lastVisitorCheckIn;
    }

    getIsFree() {
        return this.entity.isFree;
    }

    getNumber() {
        return [this.entity.number].join(' ').trim()
    }
}
